import React from 'react';

export default function TextArea({ name, value, label, placeholder, onChange }) {
  return (
    <div className="textarea">
      <label className="textarea__label" htmlFor={name}>
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        className="textarea__input"
        rows={10}
        cols={40}
      />
    </div>
  );
}
