import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import Input from '../../components/input';
import Checkbox from '../../components/checkbox';

export default function PersonalDetails({
  onClickNextStep,
  onClickPreviousStep,
  onChangeInput,
  personalDetails,
}) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <h3>{t('pages.personalDetails.title')}</h3>
      <div className="input-block">
        <h4>{t('pages.personalDetails.form.name.title')}</h4>
        <div className="input-fields">
          <Input
            key="firstName"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.name.answer.firstName.label')}
            name="firstName"
            value={personalDetails.firstName}
            type="text"
          />
          <Input
            key="lastName"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.name.answer.lastName.label')}
            name="lastName"
            value={personalDetails.lastName}
            type="text"
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.personalDetails.form.contact.title')}</h4>
        <div className="input-fields">
          <Input
            key="email"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.contact.answer.email.label')}
            name="email"
            value={personalDetails.email}
            type="text"
          />
          <Input
            key="mobile"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.contact.answer.mobile.label')}
            name="mobile"
            value={personalDetails.mobile}
            type="text"
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.personalDetails.form.account.title')}</h4>
        <div className="input-fields">
          <Input
            key="password"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.account.answer.password.label')}
            name="password"
            value={personalDetails.password}
            type="text"
          />
          <Input
            key="confirmPassword"
            onChange={onChangeInput}
            label={t('pages.personalDetails.form.account.answer.confirmPassword.label')}
            name="confirmPassword"
            value={personalDetails.confirmPassword}
            type="text"
          />
        </div>
        <div className="layout-container">
          <Checkbox
            label={
              <>
                {t('pages.personalDetails.form.terms.textFirstPart')}
                <a href="/terms" target="_blank" rel="noreferrer">
                  {t('pages.personalDetails.form.terms.link')}
                </a>
                {t('pages.personalDetails.form.terms.textSecondPart')}
              </>
            }
            onChange={onChangeInput}
            name="termsAccepted"
          />
        </div>
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={null}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
