import React from 'react';
import { ReactComponent as Room } from '../ui/assets/room.svg';
import RoomOption from './roomOption';

export default function RoomOptionContainer({ rooms, selectedRoom, onClick }) {
  const renderOptionComponents = () => {
    return rooms.map((room) => {
      return (
        <RoomOption
          key={room.type}
          selected={selectedRoom && room.type === selectedRoom.type}
          room={{ code: 1 }}
          onClick={() => onClick(room)}
        >
          <Room />
          <div>{room.name}</div>
        </RoomOption>
      );
    });
  };

  return <div className="room-options-container">{renderOptionComponents()}</div>;
}
