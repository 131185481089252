export async function createResource(uri, object) {
  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getResource(uri) {
  try {
    const response = await fetch(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function patchResource(uri, object, contentType = 'application/json') {
  try {
    const response = await fetch(uri, {
      method: 'PATCH',
      headers: {
        'Content-Type': contentType,
      },
      body: JSON.stringify(object),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteResource(uri) {
  try {
    const response = await fetch(uri, {
      method: 'DELETE',
    });
    if (response.status === 204) {
      console.log('resource deleted successfully');
    } else if (response.status === 404) {
      console.log('resource not found');
    } else {
      console.log('Error deleting resource');
    }
  } catch (error) {
    console.error(error);
  }
}
