import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function RoomOption({ room, selected, onClick, children }) {
  return (
    <div
      className={`room-option ${selected ? 'room-option--selected' : ''}`}
      onClick={() => onClick(room.type)}
    >
      <div className="room-option__checkbox">
        {selected && <Checked />}
        {!selected && <Unchecked />}
      </div>
      <div className="room-option__image">{children}</div>
    </div>
  );
}
