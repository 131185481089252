import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFile, postFile } from '../../api/files';
import { createResource, deleteResource, getResource, patchResource } from '../../api/resource';
import Wizard from '../../components/wizard';
import ColorSelection from '../color-selection/colorSelection';
import CurrentRoom from '../current-room/currentRoom';
import OfferSelection from '../offer-selection/offerSelection';
import PersonalDetails from '../personal-details/personalDetails';
import ProjectDetails from '../project-details/projectDetails';
import RoomSelection from '../room-selection/roomSelection';
import StyleSelection from '../style-selection/styleSelection';
import commitMultiSelectableObjects from './actions/commitMultiSelectableObjects';
import handleClickMultiSelectableObject from './eventHandlers/handleClickMultiSelectableObject';

export default function ProjectSteps({ wizardStep, changeWizardStep }) {
  const [project, setProject] = useState({});
  const [room, setRoom] = useState({});
  const [step, setStep] = useState(1);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [personalDetails, setPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
  });
  const [selectedPackage, setSelectedPackage] = useState({ type: '' });
  const [picturesCurrentRoom, setPicturesCurrentRoom] = useState([]);

  const colorSelection = useRef({ existing: [], active: [], new: [], toDelete: [] });
  const styleSelection = useRef({ existing: [], active: [], new: [], toDelete: [] });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      (async () => {
        const getProjectResult = await getResource(
          `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
        );
        const getRoomResult = await getResource(getProjectResult._links.room.href);
        setProject(getProjectResult);
        setRoom(getRoomResult);
      })();
    } else {
      (async () => {
        try {
          const newRoom = await createResource(`${process.env.REACT_APP_BACKEND_URL}/rooms`, {});
          setRoom(newRoom);
          const newProject = await createResource(`${process.env.REACT_APP_BACKEND_URL}/projects`, {
            room: newRoom._links.self.href,
          });
          setProject(newProject);

          navigate(`/project/${newProject._links.self.href.split('/').pop()}`, { replace: true });
        } catch (error) {
          console.error('Error creating project', error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (step === 6) {
      changeWizardStep(2);
    } else if (step === 7) {
      changeWizardStep(3);
    }
  }, [step]);

  const getColors = async () => {
    const colors = await getResource(project._links.colors.href);
    setSelectedColors(colors._embedded?.colors);
    colorSelection.current = {
      existing: colors._embedded?.colors,
      new: [],
      toDelete: [],
    };
  };

  const getStyles = async () => {
    const styles = await getResource(project._links.styles.href);
    setSelectedStyles(styles._embedded?.styles);
    styleSelection.current = {
      existing: styles._embedded?.styles,
      new: [],
      toDelete: [],
    };
  };

  const getRoomImages = async () => {
    const getRoomImagesResult = await getResource(project._links.roomImages.href);

    const result = await Promise.all(
      getRoomImagesResult._embedded?.files.map(async (file) => {
        const blob = await getFile(file._links.self.href);
        const updatedFile = { ...file, blob };
        return updatedFile;
      }),
    );
    setPicturesCurrentRoom(result);
  };

  const handleClickRoom = (selectedRoom) => {
    if (room.type === selectedRoom.type) {
      setRoom({ ...room, type: null });
      return;
    }
    setRoom({ ...room, type: selectedRoom.type });
  };

  const handleClickColor = (color) => {
    handleClickMultiSelectableObject(
      color,
      colorSelection,
      selectedColors,
      setSelectedColors,
      'colorCode',
    );
  };

  const handleClickStyle = (style) => {
    handleClickMultiSelectableObject(
      style,
      styleSelection,
      selectedStyles,
      setSelectedStyles,
      'styleName',
    );
  };

  const handleClickBudget = (budget) => {
    if (selectedBudget.id === budget.id) {
      setSelectedBudget({});
      return;
    }
    setSelectedBudget(budget);
  };

  const handleClickSchedule = (schedule) => {
    if (selectedSchedule.id === schedule.id) {
      setSelectedSchedule({});
      return;
    }
    setSelectedSchedule(schedule);
  };

  const handleChangePicturesCurrentRoom = (files) => {
    setPicturesCurrentRoom([...picturesCurrentRoom, ...files]);
  };

  const handleChangeProjectDetails = (event) => {
    const { name, value } = event.target;

    setProject({ ...project, [name]: value });
  };

  const handleChangePersonalDetails = (event) => {
    if (event.target.name === 'termsAccepted') {
      setPersonalDetails({ ...personalDetails, termsAccepted: !personalDetails.termsAccepted });
      return;
    }
    const { name, value } = event.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  const handleClickPackage = (packageType) => {
    setSelectedPackage(packageType.type);
  };

  const handleClickNextStep = async () => {
    if (step === 1) {
      if (!room.type) {
        return;
      }
      await patchResource(room._links.self.href, { type: room.type });
      await getColors();
    }
    if (step === 2) {
      await commitMultiSelectableObjects(colorSelection, project, 'colors', 'colorCode');
      await getStyles();
    }
    if (step === 3) {
      await commitMultiSelectableObjects(styleSelection, project, 'styles', 'styleName');
      await getRoomImages();
    }
    if (step === 4) {
      const fileIds = await Promise.all(picturesCurrentRoom.map((file) => postFile(file)));
      const roomImagesList = fileIds.map(
        (fileId) => `${process.env.REACT_APP_BACKEND_URL}/files/${fileId}`,
      );
      await patchResource(project._links.self.href, {
        roomImages: roomImagesList,
      });
    }
    if (step === 5) {
      await patchResource(room);
    }
    setStep(step + 1);
  };

  const handleClickPreviousStep = async () => {
    if (step === 3) {
      await getColors();
    }
    if (step === 4) {
      await getStyles();
    }
    setStep(step - 1);
  };
  const renderComponentForCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          <RoomSelection
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            selectedRoom={room}
            onClick={handleClickRoom}
          />
        );
      case 2:
        return (
          <ColorSelection
            selectedColors={selectedColors}
            onClick={handleClickColor}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
          />
        );
      case 3:
        return (
          <StyleSelection
            selectedStyles={selectedStyles}
            onClick={handleClickStyle}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
          />
        );
      case 4:
        return (
          <CurrentRoom
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            picturesCurrentRoom={picturesCurrentRoom}
            onChangePicturesCurrentRoom={handleChangePicturesCurrentRoom}
            onClickDelete={(file) => async () => {
              // first remove association with project
              const projectRoomImages = await getResource(project._links.roomImages.href);
              const roomImages = projectRoomImages._embedded.files;
              const roomImage = roomImages.filter(
                (image) => image._links.self.href !== file._links.self.href,
              );
              await patchResource(project._links.self.href, {
                roomImages: roomImage.map((image) => image._links.self.href),
              });
              await deleteResource(file._links.self.href);

              setPicturesCurrentRoom(picturesCurrentRoom.filter((picture) => picture !== file));
            }}
          />
        );
      case 5:
        return (
          <ProjectDetails
            selectedStyles={selectedStyles}
            onClick={handleClickStyle}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            projectDetails={project}
            selectedBudget={selectedBudget}
            onClickBudget={handleClickBudget}
            selectedSchedule={selectedSchedule}
            onClickSchedule={handleClickSchedule}
            onChangeInput={handleChangeProjectDetails}
          />
        );
      case 6:
        return (
          <PersonalDetails
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            personalDetails={personalDetails}
            onChangeInput={handleChangePersonalDetails}
          />
        );
      case 7:
        return (
          <OfferSelection
            onClick={handleClickPackage}
            selectedPackage={selectedPackage}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="project-steps">
      <div className="mobile-wizard">
        <Wizard step={wizardStep} />
      </div>
      <div className="project-steps__inner-container">{renderComponentForCurrentStep()}</div>
    </div>
  );
}
