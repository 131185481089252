import { createResource, deleteResource, patchResource } from '../../../api/resource';

export default async function commitMultiSelectableObjects(
  objectSelection,
  project,
  propertyName,
  codeProperty,
) {
  const createColorPromises = objectSelection.current.new.map((object) => {
    const requestObject = {};
    requestObject[codeProperty] = object[codeProperty];
    return createResource(`${process.env.REACT_APP_BACKEND_URL}/${propertyName}`, requestObject);
  });

  const newObjects = await Promise.all([...createColorPromises]);

  const filteredObjects = objectSelection.current.existing.filter((existingObject) => {
    return !objectSelection.current.toDelete.some(
      (toDeleteObject) => toDeleteObject[codeProperty] === existingObject[codeProperty],
    );
  });

  objectSelection.current.active = [...filteredObjects, ...newObjects];

  const requestObject = {
    [propertyName]: objectSelection.current.active.map((object) => object._links.self.href),
  };

  await patchResource(project._links.self.href, requestObject);

  const deleteColorPomises = objectSelection.current.toDelete.map((object) => {
    return deleteResource(object._links.self.href);
  });
  await Promise.all([...deleteColorPomises]);
  objectSelection.current.new = [];
  objectSelection.current.toDelete = [];
}
