export async function postFile(file) {
  const formdata = new FormData();
  formdata.append('file', file);

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/files`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getFile(uri) {
  try {
    const response = await fetch(`${uri}`);
    const blob = await response.blob();
    const documentURL = URL.createObjectURL(blob);
    return documentURL;
  } catch (error) {
    console.error(error);
    return null;
  }
}
