import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function ColorOption({ color, selected, onClick }) {
  return (
    <div className="color-option" onClick={() => onClick(color.id)}>
      <div className="color-option__circle" style={{ backgroundColor: color.colorCode }} />
      <div className="color-option__checkbox">
        {selected && <Checked />}
        {!selected && <Unchecked />}
      </div>
    </div>
  );
}
