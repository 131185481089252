import React from 'react';
import PackageOption from './packageOption';

export default function PackagesContainer({ selectedPackage, onClick }) {
  const packages = [
    {
      type: 'Bronze',
      price: '€239',
      oldPrice: '€299',
      showBestValueLabel: false,
    },
    {
      type: 'Silver',
      price: '€339',
      oldPrice: '€399',
      showBestValueLabel: true,
      isLarge: true,
    },
    {
      type: 'Gold',
      price: '€479',
      oldPrice: '€599',
      showBestValueLabel: false,
    },
  ];
  const renderOptionComponents = () => {
    return packages.map((option) => {
      return (
        <PackageOption
          key={option.type}
          selected={option.type === selectedPackage.type}
          option={option}
          onClick={() => onClick(option)}
          type={option.type}
          showBestValueLabel={option.showBestValueLabel}
          price={option.price}
          oldPrice={option.oldPrice}
          isLarge={option.isLarge}
        />
      );
    });
  };

  return <div className="package-options-container">{renderOptionComponents()}</div>;
}
