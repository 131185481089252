import React from 'react';

export default function Checkbox({ name, value, label, onChange }) {
  return (
    <div className="checkbox">
      <input
        id={name}
        name={name}
        type="checkbox"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        className="checkbox__input"
      />
      <label className="checkbox" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}
