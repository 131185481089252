import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorOptionsContainer from '../../components/colorOptionsContainer';
import ButtonContainer from '../../components/buttonContainer';

export default function ColorSelection({
  selectedColors,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
}) {
  const { t } = useTranslation();

  const colors = [
    { colorCode: '#5B738B' },
    { colorCode: '#D5DADD' },
    { colorCode: '#EE3939' },
    { colorCode: '#FFB2D2' },
    { colorCode: '#E76500' },
    { colorCode: '#FFDF72' },
    { colorCode: '#36A41D' },
    { colorCode: '#BDE986' },
    { colorCode: '#049F9A' },
    { colorCode: '#64EDD2' },
    { colorCode: '#1B90FF' },
    { colorCode: '#A6DFFF' },
    { colorCode: '#7858FF' },
    { colorCode: '#D3B6FF' },
    { colorCode: '#F31DED' },
    { colorCode: '#FA4F96' },
    { colorCode: '#3A3A3A' },
    { colorCode: '#FFFFFF' },
  ];

  return (
    <div className="layout-container">
      <h3>{t('pages.colorSelection.title')}</h3>
      <div className="layout-container">
        <ColorOptionsContainer colors={colors} selectedColors={selectedColors} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.colorSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
