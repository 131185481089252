import React from 'react';

export default function Input({ name, value, label, placeholder, type, onChange }) {
  return (
    <div className="input">
      <label className="input__label" htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        className="input__input"
      />
    </div>
  );
}
