import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../ui/assets/logo.svg';
import Wizard from './wizard';

export default function Navbar({ step, showWizard }) {
  const { t } = useTranslation();
  return (
    <nav className="navbar">
      <div className="navbar__logo ">
        <Logo />
      </div>
      <div className="navbar__middle-content">{showWizard && <Wizard step={step} />}</div>
      <div className="navbar__exit-button">{`${t('navbar.exitButton')} X`}</div>
    </nav>
  );
}
