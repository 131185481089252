import React from 'react';
import { useTranslation } from 'react-i18next';
import PackagesContainer from '../../components/packageOptionContainer';
import { ReactComponent as Discount } from '../../ui/assets/discount.svg';

export default function OfferSelection({ onClick, selectedPackage }) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="offer-selection">
        <div className="offer-selection__container">
          <div className="offer-selection__header">
            <h3>{t('pages.packageSelection.title')}</h3>
            <div className="offer-selection__header__subtitle">
              <div>{t('pages.packageSelection.subtitleFirstPart')}</div>
              <div className="offer-selection__header__subtitle--strong">
                {t('pages.packageSelection.subtitleSecondPart')}
              </div>
            </div>
          </div>
          <div className="offer-selection__header__icon">
            <Discount />
          </div>
        </div>
        <div className="layout-container">
          <PackagesContainer onClick={onClick} selectedPackage={selectedPackage} />
        </div>
      </div>
    </div>
  );
}
