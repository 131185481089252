export default function handleClickMultiSelectableObject(
  object,
  objectSelection,
  selectedObjects,
  setSelectedObjects,
  codeProperty,
) {
  let activeObjects = [...selectedObjects];
  if (
    activeObjects.find((selectedObject) => selectedObject[codeProperty] === object[codeProperty])
  ) {
    activeObjects = activeObjects.filter(
      (selectedObject) => selectedObject[codeProperty] !== object[codeProperty],
    );
    setSelectedObjects(activeObjects);
  } else {
    activeObjects = [...selectedObjects, object];
    setSelectedObjects(activeObjects);
  }

  objectSelection.current.new = [];
  objectSelection.current.toDelete = [];

  objectSelection.current.existing.forEach((existingColor) => {
    if (
      !activeObjects.find(
        (selectedObject) => selectedObject[codeProperty] === existingColor[codeProperty],
      )
    ) {
      objectSelection.current.toDelete.push(existingColor);
    } else {
      objectSelection.current.toDelete = objectSelection.current.toDelete.filter(
        (colorToRemove) => colorToRemove[codeProperty] !== existingColor[codeProperty],
      );
    }
  });

  activeObjects.forEach((activeObject) => {
    if (
      !objectSelection.current.existing.find(
        (selectedObject) => selectedObject[codeProperty] === activeObject[codeProperty],
      )
    ) {
      objectSelection.current.new.push(activeObject);
    } else {
      objectSelection.current.new = objectSelection.current.new.filter(
        (colorToRemove) => colorToRemove[codeProperty] !== activeObject[codeProperty],
      );
    }
  });
}
