import React from 'react';
import { useTranslation } from 'react-i18next';
import RoomOptionContainer from '../../components/roomOptionContainer';
import ButtonContainer from '../../components/buttonContainer';

export default function RoomSelection({ selectedRoom, onClick, onClickNextStep }) {
  const { t, i18n } = useTranslation();

  const rooms = [
    { type: 'BEDROOM', name: `${t('pages.roomSelection.roomOption.bedroom')} ` },
    { type: 'LIVING_ROOM', name: `${t('pages.roomSelection.roomOption.livingRoom')} ` },
    { type: 'KITCHEN', name: `${t('pages.roomSelection.roomOption.kitchen')} ` },
    { type: 'BATHROOM', name: `${t('pages.roomSelection.roomOption.bathroom')} ` },
  ];

  return (
    <div className="layout-container">
      <h3>
        {`${t('pages.roomSelection.title')}
        ${t('pages.roomSelection.titleSecondLine')} `}
      </h3>
      <div style={{ marginTop: '2rem', display: 'flex', gap: '1rem' }}>
        <button
          type="button"
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr');
          }}
        >
          Change language
        </button>
      </div>
      <div className="layout-container">
        <RoomOptionContainer rooms={rooms} selectedRoom={selectedRoom} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep={false}
          firstButtonCaption={t('pages.roomSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
