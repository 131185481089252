import React from 'react';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as Info } from '../ui/assets/info.svg';

export default function TooltipComponent({ hoverText }) {
  return (
    <div className="package__more-included__item__hover-icon">
      <div data-tooltip-id="tooltip" data-tooltip-content={hoverText}>
        <Info />
      </div>
      <Tooltip
        id="tooltip"
        style={{
          backgroundColor: 'white',
          color: '#403f3f',
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '5px',
        }}
      />
    </div>
  );
}
