import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function EnumerationOption({ option, selected, onClick }) {
  return (
    <div
      className={`enumeration-option ${selected ? 'enumeration-option--selected' : ''}`}
      onClick={() => onClick(option.id)}
    >
      <div className="enumeration-option__checkbox">
        {selected && <Checked />}
        {!selected && <Unchecked />}
      </div>
      <div className="enumeration-option__caption">{option.caption}</div>
    </div>
  );
}
