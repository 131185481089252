import React from 'react';

export default function Wizard({ step }) {
  const retrieveStepStateClass = (stepNumber) => {
    if (step === stepNumber) {
      return 'wizard__step--active';
    }
    return '';
  };

  const retrieveLineStateClass = (stepNumber) => {
    if (step === stepNumber) {
      return 'wizard__line--active';
    }
    return '';
  };

  return (
    <div className="wizard">
      <div className={`wizard__step  ${retrieveStepStateClass(1)}`}>1</div>
      <div className={`wizard__line wizard__line--first ${retrieveLineStateClass(1)}`} />
      <div className={`wizard__step ${retrieveStepStateClass(2)}`}>2</div>
      <div className={`wizard__line ${retrieveLineStateClass(2)}`} />
      <div className={`wizard__step ${retrieveStepStateClass(3)}`}>3</div>
      <div className={`wizard__line  ${retrieveLineStateClass(3)}`} />
      <div className={`wizard__step ${retrieveStepStateClass(4)}`}>4</div>
    </div>
  );
}
