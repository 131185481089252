import React from 'react';
import { useTranslation } from 'react-i18next';
import StyleOptionContainer from '../../components/styleOptionsContainer';
import ButtonContainer from '../../components/buttonContainer';
import lightScandiStyle from '../../ui/assets/lightScandiStyle.png';
import cozyStyle from '../../ui/assets/cozyStyle.png';
import blueishStyle from '../../ui/assets/blueishStyle.png';
import organicStyle from '../../ui/assets/organicStyle.png';
import classicStyle from '../../ui/assets/classicStyle.png';
import grayStyle from '../../ui/assets/grayStyle.png';

export default function StyleSelection({
  selectedStyles,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
}) {
  const { t } = useTranslation();

  const styles = [
    { styleName: 'SCANDINAVIAN', image: lightScandiStyle },
    { styleName: 'TRADITIONAL', image: grayStyle },
    { styleName: 'INDUSTRIAL', image: cozyStyle },
    { styleName: 'ECLECTIC', image: blueishStyle },
    { styleName: 'FARMHOUSE', image: organicStyle },
    { styleName: 'CLASSIC', image: classicStyle },
  ];

  return (
    <div className="layout-container">
      <h3>{t('pages.styleSelection.title')}</h3>
      <div className="layout-container">
        <StyleOptionContainer styles={styles} selectedStyles={selectedStyles} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.styleSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
